import NavbarCoreSection from './sections/NavbarCoreSection';
import NavbarAdditionalSection from './sections/NavbarAdditionalSection';
import './styles/NavbarV2.scss';
import NavbarDropBlock from './common/NavbarDropBlock';
import NavbarMobileMenu from './elements/NavbarMobileMenu';
import {
  lazy,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import { useNavbar } from '../../Navbar/useNavbar';
import NavbarBurger from './controls/NavbarBurger';
import { useSearchConfig } from '../../ZimSearch/useSearchConfig';
import { useNavbarStore } from './navbarContext';
import { getData } from '../../../services/crud';
import { env } from '../../../environments/environment';
import { useCookies } from 'react-cookie';
import { UserLoginData } from '../../../types/dto/UserLogin';
import MyZimUserProvider from '../../../shared/MyZimUserContext';
import { useVisibility } from './hooks/useVisibility';
import { appHelper } from '../../../helpers/appHelper';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import WithRtl from '../../../shared/WithRtl';
import { useIsMobileShared } from '../../../hooks/useIsMobileShared';

const RTL = lazy(() => import('./NavbarV2Rtl'));
const ignoreMainMenuTypes = ['ineed', 'Logo', 'TabletLogo'];
const NAV_SCROLL_OFFSET = 150;
const isRoot = document.querySelector('html')?.getAttribute('data-is-root') === 'True';
type ScrollDirection = 'down' | 'up';
type ScrollState = {
  direction: ScrollDirection;
};

const scrollState: ScrollState = {
  direction: 'up'
};

const scrollReducer = (state: ScrollState, action: ScrollDirection): ScrollState => {
  switch (action) {
    case 'down': {
      if (state.direction === 'down') return state;

      return {
        ...state,
        direction: 'down'
      };
    }
    case 'up': {
      if (state.direction === 'up') return state;

      return {
        ...state,
        direction: 'up'
      };
    }
    default:
      return state;
  }
};

const NavbarV2 = ({
  countryRedirects,
  defaultValues
}: {
  countryRedirects?: { CountryCode: string; Prefix: string; LanguageCode: string }[];
  defaultValues: any;
}) => {
  const bodyElem = useRef(document.querySelector('body'));

  const onMobileBurgerToggle = (isOpen: boolean) => {
    isOpen
      ? bodyElem.current.classList.remove('mobile-open')
      : bodyElem.current.classList.add('mobile-open');
  };
  const navbarRef = useRef<HTMLDivElement>(null);
  const [scroll, dispatchScroll] = useReducer(scrollReducer, scrollState);
  const [{ idmConfig }, setNavbarStore] = useNavbarStore((s) => s);
  const [{ country }] = useCookies(['country']);
  const [cookies, setCookie] = useCookies(['country', 'countryName', 'langPath']);

  const initUserLogins = async () => {
    try {
      const { logins, idmConfig } = await getData<UserLoginData>(
        env.dynamic['REACT_APP_CONNECT_TO_APIM']
          ? `${env.fixed.apimEndpoints.userLogins}/${country}`
          : `${env.fixed.navbar.userLogins}/${country}`
      );
      setNavbarStore({ logins, idmConfig });
    } catch {
      setNavbarStore({
        logins: [],
        idmConfig: null
      });
    }
  };

  const cookieDomain = useMemo(() => {
    const domain = appHelper.getDomainWithoutSubdomain().split('/')[0];
    if (domain === 'localhost') {
      return domain;
    }
    return `.${domain}`;
  }, []);

  const initCountries = () => {
    let cookCountry = cookies.country;
    if (!cookCountry) {
      cookCountry = 'US';
      setCookie('country', 'US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
      setCookie('countryName', 'U.S.A.', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }

    Countries.map(
      (c) =>
        c.countries?.length &&
        c.countries.map((country) => {
          if (country.isoCode === cookCountry) {
            setCookie('countryName', country?.name, {
              path: '/',
              expires: appHelper.cookiesExpiryDate(),
              secure: true,
              domain: cookieDomain
            });
          }
        })
    );
  };

  useEffect(() => {
    initUserLogins();
    initCountries();
  }, []);

  const visibility = useVisibility<string>(0);
  const { toggle } = visibility;
  const mobileMenuRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const setMobileOpen = (isOpen: boolean) => {
    onMobileBurgerToggle(!isOpen);
    setIsMobileMenuOpen(isOpen);
    appHelper.greyOverlayToggle(isOpen);
  };
  const toggleBurger = () => setMobileOpen(!isMobileMenuOpen);
  const { ContactTab, Countries, Languages, MainMenu, TopMenu, geoLocation, MyZim } = useNavbar({
    countryRedirects,
    defaultValues
  });

  const { searchConfig } = useSearchConfig();

  const isMobile = useIsMobileShared(1200);
  const isHidable = useMemo(() => {
    if (isMobile) return true;

    return scroll.direction == 'down';
  }, [isMobile, scroll.direction]);

  const mainMenuItems = useMemo(() => {
    return MainMenu?.filter((i) => !ignoreMainMenuTypes.some((x) => x === i.menuType));
  }, [MainMenu]);

  const logo = useMemo(() => {
    return MainMenu?.find((i) => i?.menuType === 'Logo');
  }, [MainMenu]);

  const tabletLogo = useMemo(() => {
    return MainMenu?.find((i) => i?.menuType === 'TabletLogo');
  }, [MainMenu]);

  const iNeedTab = useMemo(() => {
    return MainMenu?.find((i) => i?.menuType === 'ineed');
  }, [MainMenu]);

  const logoDimensions = useMemo(() => {
    return isMobile
      ? {
          height: 'auto',
          width: 170
        }
      : {
          height: 112,
          width: 313
        };
  }, [isMobile]);

  const tabletLogoDimensions = useMemo(() => {
    return isMobile
      ? {
          height: 44,
          width: 60
        }
      : {
          height: 'auto',
          width: 'auto'
        };
  }, [isMobile]);

  const handleScroll = useCallback(() => {
    const st = window.scrollY || document.documentElement.scrollTop;
    if (st < NAV_SCROLL_OFFSET) {
      dispatchScroll('up');
      return;
    }

    dispatchScroll('down');
  }, [navbarRef, isMobile]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [handleScroll]);

  useOutsideClick(navbarRef, () => {
    toggle(null);
    setIsMobileMenuOpen(false);
    appHelper.greyOverlayToggle(false);
  });
  useOutsideClick(mobileMenuRef, () => {
    if (isMobileMenuOpen) {
      setMobileOpen(false);
    }
  });
  return (
    <>
      <WithRtl lazyElement={RTL} />
      <MyZimUserProvider idmConfig={idmConfig}>
        <div
          className={cn('navbar-v2-top', {
            'navbar-v2-scrolled': scroll.direction == 'down',
            'navbar-v2-top-not-root': !isRoot
          })}>
          <div ref={navbarRef} className="navbar-v2">
            <div className="mobile-menu" ref={mobileMenuRef}>
              <NavbarBurger isOpen={isMobileMenuOpen} onClick={toggleBurger} isRoot={isRoot} />
              <NavbarDropBlock isOpen={isMobileMenuOpen} contentClassName="navbar-v2-menu-block">
                <NavbarMobileMenu
                  visibility={visibility}
                  searchConfig={searchConfig}
                  additionalItems={TopMenu}
                  mainItems={mainMenuItems}
                  geoLocation={geoLocation?.result}
                  languages={Languages}
                  countries={Countries}
                />
              </NavbarDropBlock>
            </div>

            {logo && (
              <div className="navbar-v2-logo">
                <a href={`/${cookies.langPath ?? ''}`}>
                  <img
                    src={logo.url}
                    alt={logo.title}
                    width={logoDimensions.width}
                    height={logoDimensions.height}
                  />
                </a>
              </div>
            )}
            {tabletLogo && (
              <div className="navbar-v2-logo-tablet">
                <a href={`/${cookies.langPath ?? ''}`}>
                  <img
                    src={tabletLogo.url}
                    alt={tabletLogo.title}
                    width={tabletLogoDimensions.width}
                    height={tabletLogoDimensions.height}
                  />
                </a>
              </div>
            )}
            <div className="navbar-v2-sections">
              {!isHidable && (
                <NavbarAdditionalSection visibility={visibility} items={TopMenu} isRoot={isRoot} />
              )}
              <NavbarCoreSection
                visibility={visibility}
                searchConfig={searchConfig}
                mainItems={mainMenuItems}
                geoLocation={geoLocation?.result}
                languages={Languages}
                countries={Countries}
                iNeedTab={iNeedTab}
                contactTab={ContactTab}
                isRoot={isRoot}
                idmConfig={idmConfig}
                myZim={MyZim}
              />
            </div>
          </div>
        </div>
      </MyZimUserProvider>
    </>
  );
};

export default NavbarV2;
